import { GetStaticProps, NextPage } from 'next';
import React from 'react';
import Button from '../components/Button';
import Seo from '../components/Seo';
import BFFApiService from '../services/BFFApiService';
import ContentfulService from '../services/ContentfulService';
import { CommonPageDataProps } from '../types';
import LayoutRedesigned from '../components/LayoutRedesigned';

type Error404PageProps = CommonPageDataProps;

const Error404Page: NextPage<Error404PageProps> = ({
  countriesWithStates,
  footerContent,
  allOffices,
}) => {
  return (
    <LayoutRedesigned
      countriesWithStates={countriesWithStates}
      footerContent={footerContent}
      allOffices={allOffices}
    >
      <Seo title='Server Error' description='Server Error' />
      <div className='w-screen h-screen bg-white text-dark flex items-center md:items-start md:mt-36'>
        <div className='p-8 mx-auto w-full max-w-4xl flex flex-col items-center space-y-10'>
          <p className='text-4xl md:text-7xl font-primary-medium text-center'>
            This page does not exist
          </p>
          <p className='text-xl md:text-3xl font-primary-light text-center w-full max-w-2xl'>
            Make sure that the URL you typed in is accurate. Still can&apos;t
            find the right page?
          </p>
          <Button
            title='Search for an agent'
            variant='outline'
            href='/search-agent'
          />
        </div>
      </div>
    </LayoutRedesigned>
  );
};

export const getStaticProps: GetStaticProps<Error404PageProps> = async () => {
  const contentfulService = new ContentfulService();
  const bffApiService = new BFFApiService();

  const [countriesWithStates, footerContent, allOffices] = await Promise.all([
    contentfulService.getCountriesWithStates(),
    contentfulService.getFooterContent(),
    bffApiService.fetchAllOfficialOffices(),
  ]);

  if (!countriesWithStates || !footerContent) {
    return { notFound: true, revalidate: 1 };
  }

  return {
    props: {
      countriesWithStates,
      footerContent,
      allOffices,
    },
    revalidate: 900,
  };
};

export default Error404Page;
